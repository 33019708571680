import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import PageTitle from '../../components/PageTitle';
import axios from 'axios';
import { accessToken} from '../../components/ValidationRegex';
import { Link, Navigate, useParams } from 'react-router-dom';
import { DURATIONLIST, FREQUENCYLIST, apiBaseUrl} from '../../components/ConstantUtils';

import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';


export default function EditPrescription() {
  	const { id } = useParams()
	const [patientData, setPatientData] = useState()

	const intialFormValues = {
		"medicine": "",
		"medicine_name": "",
		"dose": "",
		"frequency": "",
		"duration_count": "",
		"duration_name": "",
		"instruction": ""
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");

	const [prescriptionMedicineList, setPrescriptionMedicineList] = useState([]);
	const [medicineList, setMedicineList] = useState();
	const [medicineListInKeyValue, setMedicineListInKeyValue] = useState();

	const [existingPrescriptionList, setExistingPrescriptionList] = useState([]);

    // let prescription_input = {
    //     "created_by": "",
    //     "dose": "",
    //     "duration_count": "",
    //     "frequency": "",
    //     "instruction": "",
    //     "medicine": "" // id
    // }



	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/book/appointment/"+id,
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			setPatientData(data)
			
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/book/prescription/item/"+id,
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
            console.log("Existing Medicine list From API == ", data)
            let new_data = []
            for (var i=0; i < data.length; i++) {
                let a = {
                    "created_by": localStorage.getItem('user'),
                    "dose": data[i]['dose'],
                    "duration_count": data[i]['duration_count'],
                    "duration_name": data[i]['duration_name'],
                    "frequency": data[i]['frequency'],
                    "instruction": data[i]['instruction'],
                    "medicine": data[i]['medicine_id'] 
                }
                new_data.push(a)
            }

            setPrescriptionMedicineList(new_data)

			// setExistingPrescriptionList(data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/inventory/medicine",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			var new_data = []
			var key_medicines = {}
			for (var i =0; i < data.length; i++) {
				new_data.push({text: data[i]['name'], id: data[i]['id']});
				key_medicines[data[i]['id']] = data[i]['name'];
			}
			setMedicineListInKeyValue(key_medicines);
			setMedicineList(new_data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	const handleCreatePrescriptionButton = () => {
		axios({
			url: apiBaseUrl+ "api/book/prescription",
			method: "POST",
			headers: {authorization: "Bearer "+accessToken},
			data: {
				'items': prescriptionMedicineList,				
				'patient': patientData ? patientData.patient.id: "",
				'appointment': id,
				'branch': localStorage.getItem('branch_id'),
				'doctor': localStorage.getItem('user'),
				'created_by': localStorage.getItem('user')
			} 
		}).then((res) => { 
			var data = res.data.data;
			setExistingPrescriptionList(data)
            setFormSubmitError("Prescription has been updated.")
		}).catch((err) => { 
			if (err.response.status == 401) {
				return <Navigate to="/login" replace />;
			}
			setFormSubmitError("Prescription already exist with this appointment for the same patient.")
		});
	}

	const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.medicine == "") {
			errors.medicine = "This field is required";
		}

		if (formDataObject.dose == "") {
			errors.dose = "This field is required";
		}

		if (formDataObject.frequency == "") {
			errors.dose = "This field is required";
		}

		if (formDataObject.duration_count == "") {
			errors.duration_name = "This field is required";
		}

		if (formDataObject.duration_name == "") {
			errors.duration_name = "This field is required";
		}

		if (formDataObject.instruction == "") {
			errors.instruction = "This field is required";
		}

		return errors;
	}


	const handleFormSubmit = (e) => {
        console.log("0.  Ready to submit == ", prescriptionMedicineList)
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	} 


	// Submit Form to the servers
	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
            
            console.log("1.  Ready to submit == ", prescriptionMedicineList)

			const formObject = document.querySelector(".prescription_form");
			const formData = new FormData(formObject);
			var object = {
				'created_by': localStorage.getItem('id')
			};

			formData.forEach(function(value, key){
    			object[key] = value;
			});

			prescriptionMedicineList.push(object);
			setPrescriptionMedicineList(prescriptionMedicineList);
			setFormValues(intialFormValues);
			setIsSubmit(false);
			setFormError({});
		}
	}, [formErrors]);


	// Handle Input change event
	const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}


	const deletePrecriptionItem = (e) => {
        let dataIndex = parseInt(e.target.getAttribute("data-index"));
        let new_medicine_list = []
        for (var i = 0; i < prescriptionMedicineList.length; i++) {
            if (i != dataIndex) {
                new_medicine_list.push(prescriptionMedicineList[i]);
            }
        }
        setPrescriptionMedicineList(new_medicine_list);
    }
	

  return (
	<div className="container-fluid container-application">
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        <div className="main-content position-relative">
            <Navbar></Navbar>
            <div className="page-content">
              	<div className="row justify-content-center">


					<div className="col-lg-12">

						<div className='row mb-3'>
							<div className='col-md-5'>
								<PageTitle  link_not_required="1" pageName={patientData ? "Edit Prescription for  -  "+patientData.patient.name: ""} link_to="appointment" link_name="View All Appointment"></PageTitle>
							</div>

							{patientData ? 
							<div className='col-md-7 text-right'>
								{/* <button className="btn btn-sm btn-white btn-icon rounded-pill" >
									<span className="btn-inner--icon"><i className="fa fa-times"></i></span>
									<span className="btn-inner--text">Download Prescription</span>
								</button> */}

                                <a type="button" href={apiBaseUrl + "cms/prescription/pdf/?appointment_id="+id } className='btn btn-sm btn-white btn-icon rounded-pill'><i className='fa fa-download mr-2'></i>  Download Prescription</a>			


								<Link to={"/appointment-detail/"+id} className="btn btn-sm btn-white btn-icon rounded-pill">
									<span className="btn-inner--icon"><i className="far fa-list"></i></span>
									<span className="btn-inner--text">View Appointment</span>
								</Link>								

							</div> : ""}
						</div>
						
						<div className="card">
							<div className="card-body">

									<div id="prescription" className="container tab-pane tab_content">
										<div className='row'>
                                            {formSubmitError != "" ? 
											<div className='col-md-12 mt-3 text-center'>
                                                <div className='alert alert-success text-left'>{formSubmitError}</div>
                                            </div>: ""}
											
											<div className='col-md-4'>
												<form className="mt-3 prescription_form" action='#!'>
													<div className='col-md-12 form-group tab_content_form'>
														<label>Select Medicine</label>
														<Select2 name="medicine" value={formValues.medicine} onChange={handleChange} 
															data={medicineList ? medicineList: []}
															options={{
																placeholder: 'Select Medicine',
															}}
														/>
														<span className='error_message'>{formErrors.medicine}</span>
													</div>

													<div className='col-md-12 form-group tab_content_form'>
														<div className='row'>
															<div className='col-md-12'>
																<label>Dose and Frequency</label>
															</div>
															<div className='col-md-5 form-group'>
																<input type='text' onChange={handleChange} name="dose" value={formValues.dose} placeholder='E.g 1'  className='form-control'/>
															</div>
															<div className='col-md-7 form-group'>
																<select className='form-control' onChange={handleChange} name="frequency" value={formValues.frequency}>
																	<option value="">Select</option>
																	{FREQUENCYLIST.map((info, i) => (
																		<option>{info}</option>
																	))}
																</select>
															</div>
															<div className='col-md-12'>
																<span className='error_message'>{formErrors.dose}</span>
															</div>
														</div>
													</div>
														
													<div className='col-md-12 form-group tab_content_form'>
														<div className='row'>
															<div className='col-md-12'>
																<label>Select Course Duration</label>
															</div>
															<div className='col-md-6 form-group'>
																<input type='text' onChange={handleChange} value={formValues.duration_count} name="duration_count" placeholder='E.g 1'  className='form-control'/>
															</div>
															<div className='col-md-6 form-group'>
																<select className='form-control' onChange={handleChange} name="duration_name" value={formValues.duration_name}>
																	<option value="">Select</option>
																	{DURATIONLIST.map((info, i) => (
																		<option>{info}</option>
																	))}
																</select>
															</div>
															<div className='col-md-12'>
																<span className='error_message'>{formErrors.duration_name}</span>
															</div>
														</div>
													</div>


													<div className='form-group col-md-12 tab_content_form'>
														<label>Instruction or Remark</label>
														<textarea type='text' value={formValues.instruction} onChange={handleChange} name='instruction' className='form-control'></textarea>
														<span className='error_message'>{formErrors.instruction}</span>
													</div>

													<div className="form-group col-md-12 mt-3 tab_content_form">
														<div className="text-right">
															<button type="button" onClick={handleFormSubmit} className="btn btn-primary btn-sm rounded-pill">Add Medicine</button>
														</div>
													</div>
												</form>
											</div>
											
											<div className='col-md-8'>
												<h5 className='mt-4'>Medicine List</h5>
												<table className='table table-bordered bg-white'>
													<thead>
														<tr>
															<th>Medicine</th>
															<th>Dose & Frequency</th>
															<th>Course Duration</th>
															<th>Instruction / Remark</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>

													{prescriptionMedicineList.map((info,i) => (
														<tr>
															<td>{medicineListInKeyValue ? medicineListInKeyValue[info['medicine']]: ""}</td>
															<td>{info['dose'] + " " +  info['frequency']}</td>
															<td>{info['duration_count'] + " "+ info['duration_name']}</td>
															<td>{info['instruction']}</td>
															<td><a href="#!" onClick={deletePrecriptionItem} data-index={i}><i onClick={deletePrecriptionItem} data-index={i} className='fa fa-trash'></i></a></td>
														</tr>
													))}
													</tbody>
													
													
												</table>
												<div className='text-right'>
													<button type='button' onClick={handleCreatePrescriptionButton} className='btn btn-primary mt-3 rounded-pill'>Update Prescription</button>
												</div>
											</div>

										</div>
										
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>



    </div>
  )
}
